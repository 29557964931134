// Externals
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import * as dashboardService from 'src/services/dashboard';

// Types
import type { DashboardSales } from '../types/dashboard-sales.model';

export interface DashboardState {
  currentSales: DashboardSales;
  sales: DashboardSales;
}

const initialState: DashboardState = {
  currentSales: { cashAmount: '0', onlineAmount: '0', totalAmount: '0' },
  sales: { cashAmount: '0', onlineAmount: '0', totalAmount: '0' }
};

export const fetchCurrentSales = createAsyncThunk<{ sales: DashboardSales }, { shopId: number }>(
  'dashboard/current-sales',
  async ({ shopId }, thunkAPI) => {
    return await dashboardService.getCurrentSales({ shopId });
  }
);

export const fetchSales = createAsyncThunk<{ sales: DashboardSales }, { shopId: number }>(
  'dashboard/sales',
  async ({ shopId }, thunkAPI) => {
    return await dashboardService.getSales({ shopId });
  }
);

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentSales.fulfilled, (state, action): void => {
      state.currentSales = action.payload.sales;
    });
    builder.addCase(fetchCurrentSales.rejected, (state, action): void => {
      state.currentSales = undefined;
    });
    builder.addCase(fetchSales.fulfilled, (state, action): void => {
      state.sales = action.payload.sales;
    });
    builder.addCase(fetchSales.rejected, (state, action): void => {
      state.sales = undefined;
    });
  }
});

export const { reducer } = slice;

export default slice;
