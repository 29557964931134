import { useState } from 'react';
import type { FC, ReactNode } from 'react';

// Components
import Navbar from '../common/Navbar';
import Sidebar from './Sidebar';

// Externals
import { experimentalStyled } from '@mui/material';
import { Outlet } from 'react-router-dom';

interface LayoutProps {
  children?: ReactNode;
}

const LayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const LayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '72px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '280px'
  }
}));

const LayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const LayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const Layout: FC<LayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

  return (
    <LayoutRoot>
      <Navbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
      <Sidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        onMobileOpen={(): void => setIsSidebarMobileOpen(true)}
        openMobile={isSidebarMobileOpen}
      />
      <LayoutWrapper>
        <LayoutContainer>
          <LayoutContent id="scroll">
            <Outlet />
          </LayoutContent>
        </LayoutContainer>
      </LayoutWrapper>
    </LayoutRoot>
  );
};

export default Layout;
