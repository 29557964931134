// Components
import { requestCoreApi, requestPayoutApi } from './common';

// Types
import type { Shop } from '../types/shop.model';
import type { ShopList } from '../types/shop-list.model';
import type { ShopCommissionSetting } from '../types/shop-commission-setting.model';
import qs from 'qs';

// GET
export const exists = ({ id }): Promise<{ exists: boolean }> =>
  requestPayoutApi({ api: `/api/shops/${id}/exists`, authorized: true, method: 'GET' });

export const mine = (): Promise<Shop[]> => requestCoreApi({ api: '/api/shops/mine', authorized: true, method: 'GET' });

export const list = ({ filters, skip, take }): Promise<{ shopList: ShopList }> => {
  const params = qs.stringify({ filters, skip, take }, { allowDots: true });
  return requestPayoutApi({
    api: `/api/shops/list?${params}`,
    authorized: true,
    method: 'GET'
  });
};

export const commissionSettings = ({ id }): Promise<{ commissionsSetting: ShopCommissionSetting }> =>
  requestPayoutApi({
    api: `/api/shops/${id}/commissions/setting`,
    authorized: true,
    method: 'GET'
  });

// POST
export const addCost = ({ id, label, billableAmount }): Promise<void> =>
  requestPayoutApi({
    api: `/api/shops/${id}/statement-item`,
    authorized: true,
    method: 'POST',
    body: { label, billableAmount }
  });

// PUT
export const updateCommissionSettings = ({ id, orderCommission }): Promise<void> =>
  requestPayoutApi({
    api: `/api/shops/${id}/commissions/setting`,
    authorized: true,
    method: 'PUT',
    body: { orderCommission }
  });
