// Components
import { requestPayoutApi } from './common';

// Types
import type { DashboardSales } from '../types/dashboard-sales.model';
import type { StatementDetails } from 'src/types/statement-details.model';

// GET
export const getCurrentSales = ({ shopId }): Promise<{ sales: DashboardSales }> =>
  requestPayoutApi({
    api: `/api/dashboards/shops/${shopId}/current-sales`,
    authorized: true,
    method: 'GET'
  });

export const getSales = ({ shopId }): Promise<{ sales: DashboardSales }> =>
  requestPayoutApi({
    api: `/api/dashboards/shops/${shopId}/sales`,
    authorized: true,
    method: 'GET'
  });

export const details = ({ id }): Promise<{ details: StatementDetails }> =>
  requestPayoutApi({ api: `/api/shops/statements/${id}`, authorized: true, method: 'GET' });
