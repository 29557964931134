import type { FC, ReactNode } from 'react';

// Externals
import PropTypes from 'prop-types';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

// Store
import { useSelector } from '../store';

interface AdminGuardProps {
  children: ReactNode;
}

const AdminGuard: FC<AdminGuardProps> = ({ children }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isAuthenticated = useSelector<boolean>((state) => state.authentication.isAuthenticated);
  const isAdmin = useSelector<boolean>((state) => state.authentication.isAdmin);

  if (!isAuthenticated) {
    return <Navigate replace={true} to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />;
  }

  if (!isAdmin) {
    return <Navigate replace={true} to={`/401`} />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (searchParams.get('redirect') && location.pathname !== searchParams.get('redirect')) {
    return <Navigate replace={true} to={searchParams.get('redirect')} />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
