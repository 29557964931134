// Externals
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import * as statementService from 'src/services/statement';

// Types
import type { StatementHistory } from '../types/statement-history.model';

export interface StatementState {
  history?: StatementHistory;
}

const initialState: StatementState = {
  history: undefined
};

export const fetchHistory = createAsyncThunk<
  { statementsHistory: StatementHistory },
  { id: number; skip: number; sortings: string; take: number }
>('statement/history', async ({ id, skip, sortings, take }, thunkAPI) => {
  return await statementService.history({ id, skip, sortings, take });
});

const slice = createSlice({
  name: 'statement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHistory.fulfilled, (state, action): void => {
      state.history = action.payload.statementsHistory;
    });
    builder.addCase(fetchHistory.rejected, (state, action): void => {
      state.history = undefined;
    });
  }
});

export const { reducer } = slice;

export default slice;
