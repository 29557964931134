// Components
import { downloadFile, requestPayoutApi } from './common';

// Externals
import _ from 'lodash';

// Types
import type { ClearableShopInvoice } from '../types/clearable-shop-invoice.model';
import { PlatformClearance } from 'src/types/plateform-clearance.model';
import type { ShopInvoice } from '../types/shop-invoice.model';

// GET
export const clearable = ({ skip, sortings, take }): Promise<ClearableShopInvoice> =>
  requestPayoutApi({
    api: `/api/platforms/shops/clearable-invoices?skip=${skip}&take=${take}&sortings=${sortings}`,
    authorized: true,
    method: 'GET'
  });

export const clearances = ({ skip, sortings, take, startDate, endDate }): Promise<PlatformClearance> => {
  const params = new URLSearchParams();
  if (skip) params.append('skip', skip);
  if (sortings) params.append('sortings', sortings);
  if (take) params.append('take', take);
  if (startDate) params.append('filter.startDate', startDate.toISOString());
  if (endDate) params.append('filter.endDate', endDate.toISOString());

  const queryParams = params ? `?${params.toString()}` : '';

  return requestPayoutApi({
    api: `/api/platforms/clearances${queryParams}`,
    authorized: true,
    method: 'GET'
  });
};

export const clearancesPdf = ({ clearanceIds }): Promise<void> => {
  const params = new URLSearchParams();
  if (clearanceIds) _.map(clearanceIds, (i) => params.append('ids', i));

  const queryParams = params ? `?${params.toString()}` : '';

  return downloadFile({
    api: `/api/platforms/clearances-pdf${queryParams}`,
    authorized: true,
    method: 'GET',
    fileName: `export-clearances-${new Date().toLocaleDateString('fr-BE')}`
  });
};

export const shopInvoices = ({ skip, sortings, take }): Promise<ShopInvoice> => {
  return requestPayoutApi({
    api: `/api/platforms/shops/invoices?skip=${skip}&take=${take}&sortings=${sortings}`,
    authorized: true,
    method: 'GET'
  });
};

// POST
export const clear = ({ platformId, shopInvoiceIds }): Promise<ClearableShopInvoice> =>
  requestPayoutApi({
    api: `/api/platforms/${platformId}/clearances`,
    authorized: true,
    method: 'POST',
    body: { shopInvoiceIds }
  });
