// Config
import * as config from '../config';

// Externals
import _ from 'lodash';
import { saveAs } from 'file-saver';

export const requestCoreApi = async ({
  api,
  authorized,
  body,
  method
}: {
  api: string;
  authorized: boolean;
  body?: any;
  method: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'OPTIONS';
}): Promise<any> => {
  return new Promise(async (resolve: (json: any) => void, reject: (error: any) => void) => {
    if (!('fetch' in window)) reject(new Error('Fetch API not found, please upgrade your browser.'));

    const accessToken = window.localStorage.getItem('coreApiAccessToken');
    if (!accessToken && authorized) reject(new Error('Missing Core API Token'));

    let headers = {
      accept: 'application/json',
      ...(authorized && { Authorization: `Bearer ${accessToken}` }),
      'content-type': 'application/x-www-form-urlencoded'
    };

    let data = new URLSearchParams();
    _.forOwn(body, (value, key) => data.append(key, value));

    let options = {
      ...(body && { body: data }),
      headers,
      method
    };

    // FETCH
    try {
      const response = await fetch(config.apiCoreUrl + api, options);

      if (response.ok) {
        response.text().then((text) => {
          resolve(text.length ? JSON.parse(text) : undefined);
        });
      } else {
        response.text().then((text) => {
          reject(new Error(text));
        });
      }
    } catch (err) {
      console.error('[Common]', err);

      reject(new Error('Il semblerait que nos serveurs aient un problème. Nos développeurs sont sur le coup !'));
    }
  });
};

export const requestPayoutApi = async ({
  api,
  authorized,
  body,
  method
}: {
  api: string;
  authorized: boolean;
  body?: any;
  method: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'OPTIONS';
}): Promise<any> => {
  return new Promise(async (resolve: (json: any) => void, reject: (error: any) => void) => {
    if (!('fetch' in window)) reject(new Error('Fetch API not found, please upgrade your browser.'));

    const accessToken = window.localStorage.getItem('payoutApiAccessToken');
    if (!accessToken && authorized) reject(new Error('Missing Payout API Token'));

    let headers = {
      accept: 'application/json',
      ...(authorized && { Authorization: `Bearer ${accessToken}` }),
      'content-type': 'application/json'
    };

    let options = {
      ...(body && { body: JSON.stringify(body) }),
      headers,
      method
    };

    // FETCH
    try {
      const response = await fetch(config.apiPayoutUrl + api, options);

      if (response.ok) {
        response.text().then((text) => {
          resolve(text.length ? JSON.parse(text).data : undefined);
        });
      } else {
        response.text().then((text) => {
          reject(
            text.length
              ? new Error(JSON.parse(text).errors[0])
              : new Error('Une erreur inconnue est survenue, veuillez réessayer plus tard.')
          );
        });
      }
    } catch (err) {
      console.error('[Common]', err);

      reject(new Error('Il semblerait que nos serveurs aient un problème. Nos développeurs sont sur le coup !'));
    }
  });
};

export const downloadFile = async ({
  api,
  authorized,
  body,
  fileName,
  method
}: {
  api: string;
  authorized: boolean;
  body?: any;
  fileName?: string;
  method: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'OPTIONS';
}): Promise<any> => {
  return new Promise(async (resolve: (json: any) => void, reject: (error: any) => void) => {
    if (!('fetch' in window)) reject(new Error('Fetch API not found, please upgrade your browser.'));

    const accessToken = window.localStorage.getItem('payoutApiAccessToken');
    if (!accessToken && authorized) reject(new Error('Missing Payout API Token'));

    let headers = {
      accept: 'application/json',
      ...(authorized && { Authorization: `Bearer ${accessToken}` }),
      'content-type': 'application/json'
    };

    let options = {
      ...(body && { body: JSON.stringify(body) }),
      headers,
      method
    };

    // FETCH
    try {
      const response = await fetch(config.apiPayoutUrl + api, options);

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, fileName);
      } else {
        response.text().then((text) => {
          reject(
            text.length
              ? new Error(JSON.parse(text).errors[0])
              : new Error('Une erreur inconnue est survenue, veuillez réessayer plus tard.')
          );
        });
      }
    } catch (err) {
      console.error('[Common]', err);

      reject(new Error('Il semblerait que nos serveurs aient un problème. Nos développeurs sont sur le coup !'));
    }
  });
};
