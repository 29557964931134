// Externals
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import * as platformService from 'src/services/platform';

// Types
import type { ClearableShopInvoice } from '../types/clearable-shop-invoice.model';
import type { PlatformClearance } from '../types/plateform-clearance.model';
import type { ShopInvoice } from '../types/shop-invoice.model';

export interface PlatformState {
  clearableShopInvoice?: ClearableShopInvoice;
  clearances?: PlatformClearance;
  shopInvoices?: ShopInvoice;
}

const initialState: PlatformState = {
  clearableShopInvoice: undefined,
  clearances: undefined,
  shopInvoices: undefined
};

export const fetchClearable = createAsyncThunk<ClearableShopInvoice, { skip: number; sortings: string; take: number }>(
  'platform/clearable',
  async ({ skip, sortings, take }, thunkAPI) => {
    return await platformService.clearable({ skip, sortings, take });
  }
);

export const fetchClearances = createAsyncThunk<
  PlatformClearance,
  { skip: number; sortings: string; take: number; startDate: Date; endDate: Date }
>('platform/clearances', async ({ skip, sortings, take, startDate, endDate }, thunkAPI) => {
  return await platformService.clearances({ skip, sortings, take, startDate, endDate });
});

export const fetchShopInvoices = createAsyncThunk<ShopInvoice, { skip: number; sortings: string; take: number }>(
  'platform/shopInvoice',
  async ({ skip, sortings, take }, thunkAPI) => {
    return await platformService.shopInvoices({ skip, sortings, take });
  }
);

const slice = createSlice({
  name: 'platform',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClearable.fulfilled, (state, action): void => {
      state.clearableShopInvoice = action.payload;
    });
    builder.addCase(fetchClearable.rejected, (state, action): void => {
      state.clearableShopInvoice = undefined;
    });
    builder.addCase(fetchClearances.fulfilled, (state, action): void => {
      state.clearances = action.payload;
    });
    builder.addCase(fetchClearances.rejected, (state, action): void => {
      state.clearances = undefined;
    });
    builder.addCase(fetchShopInvoices.fulfilled, (state, action): void => {
      state.shopInvoices = action.payload;
    });
    builder.addCase(fetchShopInvoices.rejected, (state, action): void => {
      state.shopInvoices = undefined;
    });
  }
});

export const { reducer } = slice;

export default slice;
