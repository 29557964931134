import { useCallback, useEffect } from 'react';
import type { FC } from 'react';

// Externals
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';

// Hooks
import useScrollReset from './hooks/useScrollReset';

// Routes
import routes from './routes';

// Screens
import SplashScreen from './screens/SplashScreen';

// Slices
import { initialize } from './slices/authentication';

// Store
import { useDispatch, useSelector } from './store';

// Theme
import { createCustomTheme } from './theme';

const App: FC = () => {
  const dispatch = useDispatch();
  const content = useRoutes(routes);
  useScrollReset();

  const isInitialized = useSelector<boolean>((state) => state.authentication.isInitialized);

  const init = useCallback(async (): Promise<void> => {
    try {
      await dispatch(initialize()).unwrap();
    } catch (err) {
      console.error('[App]', err);
    }
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <ThemeProvider theme={createCustomTheme()}>
      <CssBaseline />
      <Toaster position="bottom-center" />
      {isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  );
};

export default App;
