import type { FC, ReactNode } from 'react';

// Externals
import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';

// Store
import { useSelector } from '../store';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const [searchParams] = useSearchParams();

  const isAuthenticated = useSelector<boolean>((state) => state.authentication.isAuthenticated);

  if (isAuthenticated) {
    return <Navigate replace={true} to={searchParams.get('redirect') ? searchParams.get('redirect') : '/'} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
