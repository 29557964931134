import type { FC } from 'react';

// Externals
import { Menu } from '@mui/icons-material';
import { AppBar, Box, Button, ButtonBase, Container, experimentalStyled, Toolbar, useMediaQuery } from '@mui/material';
import type { AppBarProps, Theme } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Slices
import { logout } from '../../slices/authentication';

// Store
import { useDispatch } from '../../store';

interface NavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const NavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: 'none',
  color: theme.palette.primary.contrastText
}));

const Navbar: FC<NavbarProps> = ({ onSidebarMobileOpen, ...other }) => {
  const dispatch = useDispatch();
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const handleLogout = async () => {
    dispatch(logout());

    navigate('/');
  };

  return (
    <NavbarRoot {...other}>
      <Toolbar
        disableGutters={true}
        sx={{
          left: {
            lg: '280px  !important'
          },
          width: {
            lg: 'calc(100% - 280px) !important'
          },
          minHeight: '72px  !important'
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {lgDown ? (
            <>
              <Box
                component={ButtonBase}
                onClick={onSidebarMobileOpen}
                sx={{
                  borderRadius: '50%',
                  transitionProperty: 'opacity',
                  height: 48,
                  width: 48,
                  '&': {
                    background:
                      'linear-gradient(90deg, #E6007E 0%, #E70E79 7.9%, #EA3B69 37.39%, #ED5C5D 63.64%, #EE7156 85.42%, #EF7853 100.04%)',
                    color: '#FFFFFF'
                  },
                  '&:disabled': {
                    background: '#C4C4C4',
                    color: '#FFFFFF'
                  },
                  '&:hover': {
                    opacity: 0.8
                  }
                }}
              >
                <Menu />
              </Box>
              <Button color="inherit" onClick={handleLogout} type="submit" variant="outlined">
                Se déconnecter
              </Button>
            </>
          ) : (
            <>
              <RouterLink
                style={{
                  height: 52,
                  width: 'auto'
                }}
                to="/"
              >
                <img
                  alt="logo"
                  src="/static/images/appetito.png"
                  style={{
                    height: 52,
                    width: 'auto'
                  }}
                />
              </RouterLink>
              <Button color="inherit" onClick={handleLogout} type="submit" variant="outlined">
                Se déconnecter
              </Button>
            </>
          )}
        </Container>
      </Toolbar>
    </NavbarRoot>
  );
};

Navbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default Navbar;
