import { useEffect } from 'react';
import type { FC } from 'react';

// Components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../common/NavSection';

// Externals
import { Box, Button, Drawer, SwipeableDrawer, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';
import { Launch } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

interface SidebarProps {
  onMobileClose: () => void;
  onMobileOpen: () => void;
  openMobile: boolean;
}

const items = [
  {
    title: 'Tableau de bord',
    path: '/admin/overview'
  },
  {
    title: 'Commerces',
    path: '/admin/shops'
  },
  {
    title: 'À liquider',
    path: '/admin/clearables'
  },
  {
    title: 'Factures',
    path: '/admin/invoices'
  },
  {
    title: 'Liquidations',
    path: '/admin/cleared'
  },
  {
    title: 'Transferts',
    path: '/admin/payouts'
  }
];

const Sidebar: FC<SidebarProps> = ({ onMobileClose, onMobileOpen, openMobile }) => {
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ my: { xs: 2, lg: 8 }, px: 2 }}>
          <NavSection items={items} pathname={location.pathname} />
        </Box>
      </Scrollbar>
      <Box flexGrow={1} />
      <Box
        sx={{
          display: 'flex',
          my: { xs: 2, lg: 8 },
          px: 2,
          py: 0.5
        }}
      >
        <Button
          component="a"
          href={process.env.REACT_APP_API_CORE_URL}
          sx={{
            color: '#ffffff',
            fontSize: '1.5rem',
            justifyContent: 'flex-start',
            lineHeight: '2.5rem',
            pl: 3,
            paddingRight: '0px !important',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%'
          }}
          variant="text"
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'row'
            }}
          >
            Mon commerce <Launch sx={{ ml: 1 }} />
          </Box>
        </Button>
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: 'primary.main',
            width: 280
          }
        }}
        anchor="left"
        elevation={0}
        open={true}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          backgroundColor: 'primary.main',
          width: 280
        }
      }}
      anchor="left"
      elevation={0}
      onClose={onMobileClose}
      onOpen={onMobileOpen}
      open={openMobile}
      variant="temporary"
    >
      {content}
    </SwipeableDrawer>
  );
};

Sidebar.propTypes = {
  onMobileClose: PropTypes.func,
  onMobileOpen: PropTypes.func,
  openMobile: PropTypes.bool
};

export default Sidebar;
