// Externals
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import * as invoiceService from '../services/invoice';

// Types
import type { InvoiceHistory } from '../types/invoice-history.model';

export interface InvoiceState {
  history?: InvoiceHistory;
}

const initialState: InvoiceState = {
  history: undefined
};

export const fetchHistory = createAsyncThunk<
  { invoicesHistory: InvoiceHistory },
  { id: number; skip: number; sortings: string; take: number }
>('invoice/history', async ({ id, skip, sortings, take }, thunkAPI) => {
  return await invoiceService.history({ id, skip, sortings, take });
});

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHistory.fulfilled, (state, action): void => {
      state.history = action.payload.invoicesHistory;
    });
    builder.addCase(fetchHistory.rejected, (state, action): void => {
      state.history = undefined;
    });
  }
});

export const { reducer } = slice;

export default slice;
