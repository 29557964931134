import type { FC } from 'react';

// Components
import NavItem from './NavItem';

// Externals
import { List } from '@mui/material';
import type { ListProps } from '@mui/material';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';

interface Item {
  children?: Item[];
  path?: string;
  title: string;
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname
}: {
  depth?: number;
  items: Item[];
  pathname: string;
}): JSX.Element => (
  <List disablePadding={true}>
    {items.reduce(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth
        }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  depth,
  pathname,
  item
}: {
  acc: JSX.Element[];
  depth: number;
  pathname: string;
  item: Item;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true
        },
        pathname
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false
          },
          pathname
        )
      : false;

    acc.push(
      <NavItem active={partialMatch} depth={depth} key={key} open={partialMatch} path={item.path} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem active={exactMatch} depth={depth} key={key} path={item.path} title={item.title} />);
  }

  return acc;
};

const NavSection: FC<NavSectionProps> = ({ items, pathname, ...other }) => {
  return (
    <List disablePadding={true} {...other}>
      {renderNavItems({
        items,
        pathname
      })}
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired
};

export default NavSection;
