// Externals
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import * as payoutService from '../services/payout';

// Types
import type { Paging } from '../types/paging.model';
import type { ShopPayoutFail } from '../types/shop-payout-fail.model';

export interface PayoutState {
  fails?: Paging<ShopPayoutFail>;
}

const initialState: PayoutState = {
  fails: undefined
};

export const fetchFails = createAsyncThunk<
  { payoutFailsList: Paging<ShopPayoutFail> },
  { filters: { hideResent: boolean; shopName: string }; skip: number; sortings: string; take: number }
>('payout/fails', async ({ filters, skip, sortings, take }, thunkAPI) => {
  return await payoutService.fails({ filters, skip, sortings, take });
});

const slice = createSlice({
  name: 'payout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFails.fulfilled, (state, action): void => {
      state.fails = action.payload.payoutFailsList;
    });
    builder.addCase(fetchFails.rejected, (state, action): void => {
      state.fails = undefined;
    });
  }
});

export const { reducer } = slice;

export default slice;
