// Externals
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import * as shopService from '../services/shop';

// Types
import type { ShopList } from '../types/shop-list.model';

export interface ShopState {
  list?: ShopList;
}

const initialState: ShopState = {
  list: undefined
};

export const fetchList = createAsyncThunk<
  { shopList: ShopList },
  { filters: { name: string }; skip: number; take: number }
>('shop/list', async ({ filters, skip, take }, thunkAPI) => {
  return await shopService.list({ filters, skip, take });
});

const slice = createSlice({
  name: 'shop',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchList.fulfilled, (state, action): void => {
      state.list = action.payload.shopList;
    });
    builder.addCase(fetchList.rejected, (state, action): void => {
      state.list = undefined;
    });
  }
});

export const { reducer } = slice;

export default slice;
