// Components
import { requestPayoutApi } from './common';

// Types
import type { Paging } from '../types/paging.model';
import type { ShopPayoutFail } from '../types/shop-payout-fail.model';
import qs from 'qs';

// GET
export const fails = ({ filters, skip, sortings, take }): Promise<{ payoutFailsList: Paging<ShopPayoutFail> }> => {
  const params = qs.stringify({ filters, skip, sortings, take }, { allowDots: true });
  return requestPayoutApi({
    api: `/api/shops/payout-fails?${params}`,
    authorized: true,
    method: 'GET'
  });
};

// PUT
export const resend = (shopPayoutFailId): Promise<void> =>
  requestPayoutApi({
    api: `/api/shops/payouts/resend-failed-payout/${shopPayoutFailId}`,
    authorized: true,
    method: 'PUT'
  });
