import { useState } from 'react';
import type { FC, ReactNode } from 'react';

// Externals
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { alpha, Box, Button, Collapse, ListItem } from '@mui/material';
import type { ListItemProps } from '@mui/material';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  open?: boolean;
  path?: string;
  title: string;
}

const NavItem: FC<NavItemProps> = ({ active, children, depth, open: openProp, path, title, ...other }) => {
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters={true}
        sx={{
          display: 'block',
          py: 0.5
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? (
              <KeyboardArrowRight
                sx={{
                  fontSize: '2rem !important',
                  lineHeight: '2.35rem !important'
                }}
              />
            ) : (
              <KeyboardArrowDown
                sx={{
                  fontSize: '2rem !important',
                  lineHeight: '2.35rem !important'
                }}
              />
            )
          }
          onClick={handleToggle}
          sx={{
            color: '#ffffff',
            fontSize: '1.5rem',
            justifyContent: 'flex-start',
            lineHeight: '2.35rem',
            pl: 3,
            pr: '0px !important',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%'
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters={true}
      sx={{
        display: 'flex',
        py: 0.5
      }}
    >
      <Button
        component={path && RouterLink}
        sx={{
          color: '#ffffff',
          fontSize: depth === 0 ? '1.5rem' : '1rem',
          justifyContent: 'flex-start',
          lineHeight: depth === 0 ? '2.35rem' : '1.5rem',
          px: 3,
          textAlign: 'left',
          textTransform: 'none',
          width: '100%',
          ...(active && {
            background: (theme) => `${alpha(theme.palette.common.white, 0.85)} !important`,
            color: 'primary.main'
          })
        }}
        to={path}
        variant="text"
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
