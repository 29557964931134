// Externals
import { combineReducers } from '@reduxjs/toolkit';

// Slices
import { reducer as authenticationReducer } from '../slices/authentication';
import { reducer as dashboardReducer } from '../slices/dashboard';
import { reducer as invoiceReducer } from '../slices/invoice';
import { reducer as payoutReducer } from '../slices/payout';
import { reducer as platformReducer } from '../slices/platform';
import { reducer as shopReducer } from '../slices/shop';
import { reducer as statementReducer } from '../slices/statement';

const reducer = combineReducers({
  authentication: authenticationReducer,
  dashboard: dashboardReducer,
  invoice: invoiceReducer,
  payout: payoutReducer,
  platform: platformReducer,
  shop: shopReducer,
  statement: statementReducer
});

export default reducer;
