// Components
import { requestCoreApi, requestPayoutApi } from './common';

// GET
export const me = (): Promise<{ email: string; isAdmin: boolean }> =>
  requestCoreApi({ api: '/api/user/me', authorized: true, method: 'GET' });

// POST
export const authenticate = ({ token }): Promise<{ access_token: string }> =>
  requestPayoutApi({
    api: '/api/authentication/authenticate',
    authorized: false,
    body: { token: token },
    method: 'POST'
  });

export const login = ({ username, password }): Promise<{ access_token: string; email: string }> =>
  requestCoreApi({
    api: '/token',
    authorized: false,
    body: { username: username, password: password, grant_type: 'password' },
    method: 'POST'
  });
