// Externals
import { createTheme, responsiveFontSizes } from '@mui/material';
import type { Theme, ThemeOptions } from '@mui/material';
import merge from 'lodash/merge';

const themeOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        containedInherit: {
          transitionProperty: 'opacity',
          '&': {
            background:
              'linear-gradient(90deg, #E6007E 0%, #E70E79 7.9%, #EA3B69 37.39%, #ED5C5D 63.64%, #EE7156 85.42%, #EF7853 100.04%)',
            color: '#ffffff'
          },
          '&:disabled': {
            background: '#C4C4C4',
            color: '#ffffff'
          },
          '&:hover': {
            opacity: 0.8
          }
        },
        outlinedInherit: {
          transitionProperty: 'opacity',
          '&': {
            background:
              'linear-gradient(#fdfdfd, #fdfdfd) padding-box, linear-gradient(90deg, #E6007E 0%, #E70E79 7.9%, #EA3B69 37.39%, #ED5C5D 63.64%, #EE7156 85.42%, #EF7853 100.04%) border-box',
            borderColor: 'transparent',
            borderWidth: 2,
            color: '#3f3f3d'
          },
          '&:disabled': {
            background: 'none',
            borderColor: '#C4C4C4',
            borderWidth: 2,
            color: '#C4C4C4'
          },
          '&:hover': {
            background:
              'linear-gradient(#fdfdfd, #fdfdfd) padding-box, linear-gradient(90deg, #E6007E 0%, #E70E79 7.9%, #EA3B69 37.39%, #ED5C5D 63.64%, #EE7156 85.42%, #EF7853 100.04%) border-box',
            borderColor: 'transparent',
            borderWidth: 2,
            color: '#3f3f3d',
            opacity: 0.8
          }
        },
        sizeSmall: {
          fontSize: '1rem',
          fontWeight: 700,
          lineHeight: '1.25rem',
          '&.MuiButton-contained': {
            paddingBottom: 6,
            paddingTop: 6,
            paddingLeft: 16,
            paddingRight: 16
          },
          '&.MuiButton-outlined': {
            paddingBottom: 4,
            paddingTop: 4,
            paddingLeft: 16,
            paddingRight: 16
          },
          '&.MuiButton-text': {
            paddingBottom: 6,
            paddingTop: 6,
            paddingLeft: 16,
            paddingRight: 16
          }
        },
        sizeMedium: {
          fontSize: '1.25rem',
          fontWeight: 700,
          lineHeight: '1.5rem',
          '&.MuiButton-contained': {
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 32,
            paddingRight: 32
          },
          '&.MuiButton-outlined': {
            paddingBottom: 8,
            paddingTop: 8,
            paddingLeft: 32,
            paddingRight: 32
          },
          '&.MuiButton-text': {
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 32,
            paddingRight: 32
          }
        },
        sizeLarge: {
          fontSize: '1.5rem',
          fontWeight: 700,
          lineHeight: '1.75rem',
          '&.MuiButton-contained': {
            paddingBottom: 12,
            paddingTop: 12,
            paddingLeft: 48,
            paddingRight: 48
          },
          '&.MuiButton-outlined': {
            paddingBottom: 10,
            paddingTop: 10,
            paddingLeft: 48,
            paddingRight: 48
          },
          '&.MuiButton-text': {
            paddingBottom: 12,
            paddingTop: 12,
            paddingLeft: 48,
            paddingRight: 48
          }
        },
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiCard: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          zIndex: '2000 !important'
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      }
    }
  },
  palette: {
    mode: 'light',
    background: {
      default: '#f5f5f5',
      paper: '#fdfdfd'
    },
    error: {
      main: '#f5222d'
    },
    info: {
      main: '#1890ff'
    },
    success: {
      main: '#52c41a'
    },
    warning: {
      main: '#faad14'
    },
    primary: {
      main: '#ee7753',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#e70e79',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#3f3f3d',
      secondary: '#747474'
    }
  },
  shadows: [
    'none',
    '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
    '0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)'
  ],
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {},
    h2: {},
    h3: {},
    h4: {
      fontSize: '1.5rem',
      fontWeight: 700
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 700
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '18px'
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '18px'
    }
    // subtitle1: {},
    // subtitle2: {},
    // caption: {}
  }
};

export const createCustomTheme = (): Theme => {
  let theme = createTheme(merge({}, themeOptions));

  theme = responsiveFontSizes(theme);

  return theme;
};
