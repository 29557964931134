import { lazy, Suspense } from 'react';

// Externals
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

// Guard
import AdminGuard from './components/AdminGuard';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';

// Layouts
import AdminLayout from './layouts/admin/Layout';
import BaseLayout from './layouts/base/Layout';

// Screens
import LoadingScreen from './screens/LoadingScreen';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Admin pages

const AdminClearables = Loadable(lazy(() => import('./pages/admin/clearables')));
const AdminCleared = Loadable(lazy(() => import('./pages/admin/cleared')));
const AdminInvoices = Loadable(lazy(() => import('./pages/admin/invoices')));
const AdminOverview = Loadable(lazy(() => import('./pages/admin/overview')));
const AdminPayout = Loadable(lazy(() => import('./pages/admin/payouts')));
const AdminShops = Loadable(lazy(() => import('./pages/admin/shops')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/login')));
const Redirection = Loadable(lazy(() => import('./pages/authentication/redirection')));

// Dashboard pages

const Invoices = Loadable(lazy(() => import('./pages/dashboard/invoices')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/overview')));
const Statements = Loadable(lazy(() => import('./pages/dashboard/statements')));

// Other pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <BaseLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'invoices',
        element: <Invoices />
      },
      {
        path: 'overview',
        element: <Overview />
      },
      {
        path: 'statements',
        element: <Statements />
      },
      {
        path: '/',
        element: <Navigate replace={true} to="/overview" />
      }
    ]
  },
  {
    path: 'admin',
    element: (
      <AdminGuard>
        <AdminLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: 'clearables',
        element: <AdminClearables />
      },
      {
        path: 'cleared',
        element: <AdminCleared />
      },
      {
        path: 'invoices',
        element: <AdminInvoices />
      },
      {
        path: 'overview',
        element: <AdminOverview />
      },
      {
        path: 'payouts',
        element: <AdminPayout />
      },
      {
        path: 'shops',
        element: <AdminShops />
      },
      {
        path: '',
        element: <Navigate replace={true} to="/admin/overview" />
      }
    ]
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'redirection',
    element: <Redirection />
  },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '*',
    element: <Navigate replace={true} to="/404" />
  }
];

export default routes;
