// Components
import { requestPayoutApi } from './common';

// Types
import type { StatementDetails } from '../types/statement-details.model';
import type { StatementHistory } from '../types/statement-history.model';

// GET
export const history = ({ id, skip, sortings, take }): Promise<{ statementsHistory: StatementHistory }> =>
  requestPayoutApi({
    api: `/api/shops/${id}/statements/history?skip=${skip}&take=${take}&sortings=${sortings}`,
    authorized: true,
    method: 'GET'
  });

export const details = ({ id }): Promise<{ details: StatementDetails }> =>
  requestPayoutApi({ api: `/api/shops/statements/${id}`, authorized: true, method: 'GET' });
