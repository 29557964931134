// Components
import { downloadFile, requestPayoutApi } from './common';

// Types
import type { InvoiceHistory } from '../types/invoice-history.model';
import type { InvoiceDetails } from '../types/invoice-details.model';

// GET
export const history = ({ id, skip, sortings, take }): Promise<{ invoicesHistory: InvoiceHistory }> =>
  requestPayoutApi({
    api: `/api/shops/${id}/invoices/history?skip=${skip}&take=${take}&sortings=${sortings}`,
    authorized: true,
    method: 'GET'
  });

export const details = ({ id }): Promise<{ invoiceDetails: InvoiceDetails }> =>
  requestPayoutApi({ api: `/api/shops/invoices/${id}`, authorized: true, method: 'GET' });

export const shopInvoicesPdf = ({ shopId, startDate, endDate }): Promise<void> =>
  downloadFile({
    api: `/api/shops/${shopId}/invoices/pdf`,
    authorized: true,
    body: {
      startDate: startDate,
      endDate: endDate
    },
    method: 'POST',
    fileName: `export-${startDate.toLocaleDateString('fr-BE')}-${endDate.toLocaleDateString('fr-BE')}`
  });

export const shopsInvoicesPdf = ({ startDate, endDate }): Promise<void> =>
  downloadFile({
    api: `/api/shops/invoices/pdf`,
    authorized: true,
    body: {
      startDate: startDate,
      endDate: endDate
    },
    method: 'POST',
    fileName: `export-${startDate.toLocaleDateString('fr-BE')}-${endDate.toLocaleDateString('fr-BE')}`
  });
