// Externals
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Services
import * as authenticationService from '../services/authentication';
import * as shopService from '../services/shop';

// Store
import type { AppThunk } from '../store';

// Types
import type { Shop } from '../types/shop.model';
import type { User } from '../types/user.model';

export interface AuthenticationState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isAdmin: boolean;
  shop?: Shop;
  user?: User;
}

const initialState: AuthenticationState = {
  isAuthenticated: false,
  isInitialized: false,
  isAdmin: false,
  shop: undefined,
  user: undefined
};

export const initialize = createAsyncThunk<
  { isAuthenticated: boolean; isAdmin: boolean; shop: Shop | undefined; user: User | undefined },
  void
>('authentication/initialize', async (_, thunkAPI) => {
  // Get Core API Token
  const core_api_access_token: string = window.localStorage.getItem('coreApiAccessToken') as string;
  const payout_api_access_token: string = window.localStorage.getItem('payoutApiAccessToken') as string;

  // Get user
  if (core_api_access_token && payout_api_access_token) {
    // Call API
    const { email, isAdmin } = await authenticationService.me();

    const shops = await shopService.mine();
    if (!shops && !isAdmin)
      throw new Error("Pour accéder à la plateforme, veuillez d'abord associer un commerce à votre compte.");

    if (!isAdmin) {
      const { exists } = await shopService.exists({ id: shops[0].id });
      if (!exists) throw new Error("Votre compte n'a pas encore été configuré par votre administrateur.");
    }

    return {
      isAuthenticated: true,
      isAdmin,
      shop: isAdmin ? undefined : shops[0],
      user: { email }
    };
  } else {
    return {
      isAuthenticated: false,
      isAdmin: false,
      shop: undefined,
      user: undefined
    };
  }
});

export const login = createAsyncThunk<
  { isAuthenticated: boolean; isAdmin: boolean; shop: Shop; user: User },
  { username: string; password: string }
>('authentication/login', async ({ username, password }, thunkAPI) => {
  // Fetch Core API Token
  const { access_token: core_api_access_token } = await authenticationService.login({ username, password });
  window.localStorage.setItem('coreApiAccessToken', core_api_access_token);

  // Fetch Payout API Token
  const { access_token: payout_api_access_token } = await authenticationService.authenticate({
    token: core_api_access_token
  });
  window.localStorage.setItem('payoutApiAccessToken', payout_api_access_token);

  // Call API
  const { email, isAdmin } = await authenticationService.me();

  const shops = await shopService.mine();
  if (!shops && !isAdmin) throw new Error("Pour accéder à la plateforme, veuillez d'abord créer un commerce.");

  if (!isAdmin) {
    const { exists } = await shopService.exists({ id: shops[0].id });
    if (!exists) throw new Error("Votre compte n'a pas encore été configuré par votre administrateur.");
  }

  return { isAuthenticated: true, isAdmin, shop: isAdmin ? undefined : shops[0], user: { email } };
});

export const redirection = createAsyncThunk<
  { isAuthenticated: boolean; isAdmin: boolean; shop: Shop; user: User },
  { access_token: string; email: string; isAdmin: boolean }
>('authentication/redirection', async ({ access_token, email, isAdmin }, thunkAPI) => {
  // Set Core API Token
  window.localStorage.setItem('coreApiAccessToken', access_token);

  // Fetch Payout API Token
  const { access_token: payout_api_access_token } = await authenticationService.authenticate({
    token: access_token
  });
  window.localStorage.setItem('payoutApiAccessToken', payout_api_access_token);

  const shops = await shopService.mine();
  if (!shops && !isAdmin) throw new Error("Pour accéder à la plateforme, veuillez d'abord créer un commerce.");

  if (!isAdmin) {
    const { exists } = await shopService.exists({ id: shops[0].id });
    if (!exists) throw new Error("Votre compte n'a pas encore été configuré par votre administrateur.");
  }

  return { isAuthenticated: true, isAdmin, shop: isAdmin ? undefined : shops[0], user: { email } };
});

const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout(state: AuthenticationState, action: PayloadAction<void>): void {
      state.isAuthenticated = false;
      state.isAdmin = false;
      state.shop = undefined;
      state.user = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initialize.fulfilled, (state, action): void => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.isInitialized = true;
      state.isAdmin = action.payload.isAdmin;
      state.shop = action.payload.shop;
      state.user = action.payload.user;
    });
    builder.addCase(initialize.rejected, (state, action): void => {
      state.isAuthenticated = false;
      state.isInitialized = true;
      state.isAdmin = false;
      state.shop = undefined;
      state.user = undefined;
    });
    builder.addCase(login.fulfilled, (state, action): void => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.isAdmin = action.payload.isAdmin;
      state.shop = action.payload.shop;
      state.user = action.payload.user;
    });
    builder.addCase(login.rejected, (state, action): void => {
      state.isAuthenticated = false;
      state.isAdmin = false;
      state.shop = undefined;
      state.user = undefined;
    });
    builder.addCase(redirection.fulfilled, (state, action): void => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.isAdmin = action.payload.isAdmin;
      state.shop = action.payload.shop;
      state.user = action.payload.user;
    });
    builder.addCase(redirection.rejected, (state, action): void => {
      state.isAuthenticated = false;
      state.isAdmin = false;
      state.shop = undefined;
      state.user = undefined;
    });
  }
});

export const { reducer } = slice;

export const logout =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    window.localStorage.removeItem('coreApiAccessToken');
    window.localStorage.removeItem('payoutApiAccessToken');

    dispatch(slice.actions.logout());
  };

export default slice;
