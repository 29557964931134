import { StrictMode } from 'react';

// App
import App from './App';

// CSS
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';

// DOM
import ReactDOM from 'react-dom';

// Externals
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import frLocale from 'date-fns/locale/fr';
import numeral from 'numeral';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// Store
import store from './store';

numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€'
  }
});

numeral.locale('fr');

Sentry.init({
  dsn: 'https://41de236d4fd04b548db960ea35623232@o182612.ingest.sentry.io/6536341',
  environment: process.env.NODE_ENV,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2
});

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst={true}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
