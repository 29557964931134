import { useEffect } from 'react';

// Externals
import { useLocation } from 'react-router-dom';

const useScrollReset = (): void => {
  const location = useLocation();

  useEffect(() => {
    document.getElementById('scroll')?.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0
    });
  }, [location.pathname]);
};

export default useScrollReset;
